<div
	[attr.aria-label]="text"
	[attr.role]="!hide ? 'dialog' : null"
	[ngClass]="{ hide: hide }"
	class="wrapper {{ customClass }} {{ lob }}"
>
	<div class="relative-wrapper">
		<div
			#innerWrapper
			[ngClass]="{ hide: hideInner, 'fullscreen-popup': isFullscreenPopup, 'safari-view': isSafari }"
			class="inner-wrapper"
		>
			<div *ngIf="!hide" class="header">
				<h1 aria-hidden="true" class="title">
					<ng-container [appTranslation]="textKey" class="title"></ng-container>
					<ng-container *ngIf="text && !textKey">{{ text | translate }}</ng-container>
				</h1>

				<button
					(click)="close()"
					[attr.aria-label]="'labels.closePopup' | translate"
					[eventData]="{
						action: eventActions.BUTTON_CLICK,
						label: gtmLabel + '.ClosePopUpButton'
					}"
					[ngClass]="{ hidden: !showCloseButton }"
					appClickEvent
					class="close-btn"
					id="close-btn"
					tabindex="0"
				>
					<i aria-hidden="true" class="material-icons"> close </i>
				</button>
			</div>
			<ng-template #viewContainerRef></ng-template>
		</div>
	</div>
</div>
