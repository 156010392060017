import { ActionsSubject, Store } from '@ngrx/store';
import {
	Component,
	Directive,
	ElementRef,
	HostListener,
	OnDestroy,
	OnInit,
	Renderer2,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { confirmPopupAction, hidePopupAction, showPopupAction } from 'src/app/store/actions/popup.actions';

import { Subscription } from 'rxjs';
import { ofType } from '@ngrx/effects';
import { IAppState } from 'src/app/store/states/app.state';
import { assign } from 'lodash';
import { getDeviceType, isSafari, MOBILE, TABLET } from 'src/app/utils/general.utils';
import { Router } from '@angular/router';
import { RoutingService } from 'src/app/services/routing.service';
import { EventActions } from '../../../../models/event-data.model';
import { LobService } from 'src/app/services/lob.service';

@Directive({
	selector: '[popup-host]',
})
export class PopupDirective {
	constructor(public viewContainerRef: ViewContainerRef) { }
}

@Component({
	selector: 'popup-wrapper',
	templateUrl: './popup-wrapper.component.html',
	styleUrls: ['./popup-wrapper.component.scss'],
})
export class PopupWrapperComponent implements OnInit, OnDestroy {
	@ViewChild(PopupDirective, { static: true }) popupHost: PopupDirective;
	@ViewChild('closeBtn', { static: true }) closeBtn: ElementRef;
	@ViewChild('innerWrapper', { static: true }) innerWrapper: ElementRef;
	@ViewChild('viewContainerRef', { read: ViewContainerRef, static: true })
	public orderedViewContainer: ViewContainerRef;

	eventActions = EventActions;
	hide: boolean;
	subLoadComponent: Subscription;
	subCloseComponent: Subscription;
	subRouter: Subscription;
	hideInner: boolean;
	showCloseButton = true;
	isFullscreenPopup = false;
	position: ClientRect;
	customClass: string;
	gtmLabel: string;
	fullScreen: boolean;
	isSafari: boolean;
	isPopupReady: boolean;
	lastFocusedEl: HTMLElement;
	domElement: HTMLElement;
	isDesktop: boolean;
	isTablet: boolean;
	innerWidth: number;
	rightOrientation: boolean;
	text: string;
	textKey: string;
	popupFocusTrap;
	lob: string;

	constructor(
		private store: Store<IAppState>,
		private routingService: RoutingService,
		private elementRef: ElementRef,
		private renderer: Renderer2,
		private actionSubject: ActionsSubject,
		private router: Router,
		private lobService: LobService
	) {
		this.innerWidth = window.innerWidth;

		const deviceType = getDeviceType();
		this.isDesktop = deviceType !== MOBILE;
		this.isTablet = deviceType === TABLET;
		this.domElement = this.elementRef.nativeElement as HTMLElement;
		this.hide = true;
		this.hideInner = true;
		this.loadComponent = this.loadComponent.bind(this);
		this.isSafari = isSafari();

		this.subLoadComponent = actionSubject
			.pipe(ofType(showPopupAction))
			.subscribe(({ componentName, payload }) => this.loadComponent(componentName, payload));

		this.subCloseComponent = actionSubject.pipe(ofType(hidePopupAction)).subscribe(() => {
			this.hidePopup();
			document.body.classList.remove('no-scroll');
			this.hide = true;
			this.hideInner = true;
			this.orderedViewContainer.clear();
		});

		this.subRouter = router.events.subscribe(() => {
			if (this.isPopupReady) {
				this.close();
			}
		});
	}

	ngOnInit(): void {
		this.routingService.closePopupWrapper.subscribe(() => {
			this.close();
		});

		this.lobService.getLob$().subscribe((lob) => {
			if (lob) {
				this.lob = lob[0];
			}
		});
	}

	@HostListener('document:click', ['$event'])
	clickout() {
		if (this.isPopupReady) {
			// todo removed beacuse it breaks forms in popup
			// if (
			// 	(!event.target.className || event.target.className.toLowerCase().indexOf('button') < 0) &&
			// 	!this.innerWrapper.nativeElement.contains(event.target)
			// ) {
			// 	this.close();
			// }
		}
	}

	@HostListener('document:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) {
		if (event.key === 'Escape' && this.isPopupReady) {
			if (this.isPopupReady) {
				this.close();
			}
		}
	}

	@HostListener('document:mousedown', ['$event'])
	onGlobalClick(event): void {
		if (event.target.className == 'relative-wrapper') {
			this.close();
		}
	}

	ngOnDestroy(): void {
		this.subLoadComponent.unsubscribe();
		this.subCloseComponent.unsubscribe();
		this.subRouter.unsubscribe();
	}

	loadComponent(componentName, data) {
		// TODO: revisit this code when sending the component class throw error ID - need refactoring
		import(`../${componentName}/${componentName}.component.ts`).then((res) => {
			const component = res[Object.keys(res)[0]];

			this.lastFocusedEl = document.activeElement as HTMLElement;
			document.body.classList.add('no-scroll');
			this.showCloseButton = true;
			this.customClass = data.customClass;
			this.isFullscreenPopup = data && !this.isDesktop && data.fullScreen;
			this.position = data && data.position;
			this.text = data.text;
			this.textKey = data.textKey;
			this.gtmLabel = data.gtmLabel;
			this.rightOrientation = data && data.rightOrientation;
			const componentRef: any = this.orderedViewContainer.createComponent(component);
			componentRef.instance.data = assign({}, data, { close: this.close, store: this.store });
			if (this.isDesktop) {
				if (data.desktopCenter) {
					this.renderer.addClass(this.domElement.getElementsByClassName('wrapper')[0], 'desktop_center');
				} else {
					const popupWidth = data.popupWidth ? data.popupWidth : 0;
					const popupHeight = data.popupHeight ? data.popupHeight : 0;

					if (popupWidth !== 0 && popupHeight !== 0) {
						this.renderer.setStyle(this.innerWrapper.nativeElement, 'width', `${popupWidth}px`);
						this.renderer.setStyle(this.innerWrapper.nativeElement, 'height', `${popupHeight}px`);
					}

					if (this.position) {
						const header = data.header ? data.header : false;
						const middleOfWidthButtonElement = (this.position.right - this.position.left) / 2;
						const topOffset = 17;
						const leftOffset = 38;
						let leftPosition;

						if (this.rightOrientation) {
							leftPosition = this.position.left - (middleOfWidthButtonElement - 31);
							this.renderer.addClass(this.innerWrapper.nativeElement, 'right-orientation');
						} else {
							leftPosition = this.position.left - (popupWidth - middleOfWidthButtonElement - leftOffset);
						}

						if (this.isTablet) {
							const overSizePosition = popupWidth + this.position.left > window.innerWidth;
							if (overSizePosition) {
								this.renderer.addClass(this.innerWrapper.nativeElement, 'oversize-position');
							} else {
								this.renderer.removeClass(this.innerWrapper.nativeElement, 'oversize-position');
								leftPosition = this.position.left - 7;
							}
						}
						this.renderer.setStyle(this.innerWrapper.nativeElement, 'left', `${leftPosition}px`);

						if (header) {
							this.renderer.addClass(this.innerWrapper.nativeElement, 'header-wrapper');
							this.renderer.setStyle(this.innerWrapper.nativeElement, 'top', `${this.position.bottom + topOffset}px`);
						} else {
							this.renderer.removeClass(this.innerWrapper.nativeElement, 'header-wrapper');
							this.renderer.setStyle(
								this.innerWrapper.nativeElement,
								'top',
								`${this.position.top - (popupHeight + topOffset)}px`
							);
						}
					}
				}
			}

			const instance = <any>componentRef.instance;
			if (instance.confirmPopup) {
				instance.confirmPopup.subscribe(() => {
					this.store.dispatch(confirmPopupAction({ popupName: data.name }));
					this.close();
				});
			}
			this.hide = false;
			setTimeout(() => {
				this.hideInner = false;
				this.isPopupReady = true;
			}, 0);
		});
	}

	hidePopup() {
		this.renderer.removeClass(this.domElement.getElementsByClassName('wrapper')[0], 'desktop_center');
		this.renderer.setStyle(this.innerWrapper.nativeElement, 'width', null);
		this.renderer.setStyle(this.innerWrapper.nativeElement, 'height', `fit-content`);
		this.renderer.setStyle(this.innerWrapper.nativeElement, 'left', `initial`);
		this.renderer.setStyle(this.innerWrapper.nativeElement, 'top', `initial`);
		this.renderer.removeClass(this.innerWrapper.nativeElement, 'right-orientation');
		this.isPopupReady = false;
		if (this.lastFocusedEl) {
			this.lastFocusedEl.focus();
		}
	}

	close() {
		this.store.dispatch(hidePopupAction());
		if (this.popupFocusTrap) {
			this.popupFocusTrap.deactivate();
		}
	}
}
